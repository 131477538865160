import ApiService from '@/core/services/ApiService';
import { Actions } from '@/store/enums/StoreEnums';
import { Module, Action, VuexModule } from 'vuex-module-decorators';

@Module
export default class EarningModule extends VuexModule {
	@Action
	[Actions.GET_TOTAL_COMISSION_EARNED](values: any) {
		ApiService.setHeader();
		return ApiService.query('api/purchase/tracking/commission-pay', {
			params: values,
		})
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}

	@Action
	[Actions.GET_TOTAL_COMISSION_PAYABLE](values: any) {
		ApiService.setHeader();
		return ApiService.query('api/payment/tracking/commission-pay', {
			params: values,
		})
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}

	@Action
	[Actions.GET_TOTAL_COMISSION_PAID](values: any) {
		ApiService.setHeader();
		return ApiService.query('api/statistics/payment/range', {
			params: values,
		})
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}

	@Action
	[Actions.GET_TOTAL_COMISSION_GENERATED](values: any) {
		ApiService.setHeader();
		return ApiService.query('api/statistics/payment/range', {
			params: values,
		})
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}
}
