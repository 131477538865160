import ApiService from '@/core/services/ApiService';
import { Actions } from '@/store/enums/StoreEnums';
import { Module, Action, VuexModule } from 'vuex-module-decorators';

@Module
export default class CampaignModule extends VuexModule {
	@Action
	[Actions.CREATE_CAMPAIGN](values: any) {
		ApiService.setHeader();
		return ApiService.post('/api/event/campaign', values)
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}

	@Action
	[Actions.GET_TOTAL_CAMPAINGS](values: any) {
		ApiService.setHeader();
		return ApiService.query('api/event/campaign/total', {
			params: values,
		})
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}

	@Action
	[Actions.ARCHIVE_CAMPAIGN](campaignId: number) {
		ApiService.setHeader();
		return ApiService.put(`api/event/campaign/${campaignId}/archive`, {})
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}
}
