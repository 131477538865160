
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import Loader from "./components/Loader.vue";

export default defineComponent({
    name: "app",
    components: { Loader },
    setup() {
        const store = useStore();
        onMounted(() => {
            /**
             * this is to override the layout config using saved data from localStorage
             * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
             */
            store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
            nextTick(() => {
                initializeComponents();
            });
        });

        const apiAzure = process.env.VUE_APP_API_URL + "/tracking.js";

        var script: any = document.createElement("script");
        script.src = apiAzure;

        document.head.appendChild(script);

        script.onload = function () {
            console.log("funciona el script");
        };

        script.onerror = function () {
            console.error("no funciona el script");
        };
    },
    data() {
        return {};
    },
    // mounted() {},
});
