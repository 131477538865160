import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { computed } from "vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "index",
    component: () => import("../views/LandingPage/LandingPage.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "not-found",
    component: () => import("../views/LandingPage/NotFound.vue"),
  },
  {
    path: "/terminos-y-condiciones",
    name: "terminos-y-condiciones",
    component: () => import("../views/LandingPage/TermAndConditions.vue"),
  },
  {
    path: "/mantenimiento",
    name: "mantenimiento",
    component: () => import("../views/LandingPage/MaintenancePage.vue"),
  },
  {
    path: "/exit",
    name: "registro-exitoso",
    component: () => import("../views/Auth/pages/SuccessfulRegistration.vue"),
  },
  {
    path: "/layout",
    component: () => import("@/layout/Layout.vue"),
    children: [
      // User
      {
        path: "/mis-campanas",
        name: "mis-campanas",
        component: () => import("@/views/User/MyCampaigns.vue"),
      },
      {
        path: "/perfil",
        name: "perfil",
        component: () => import("@/views/User/Profile.vue"),
      },
      {
        path: "/generar-campanas",
        name: "generar-campañas",
        component: () => import("@/views/User/GenerateCampaign.vue"),
      },

      {
        path: "/mis-ganancias",
        name: "mis-ganancias",
        component: () => import("@/views/User/MyEarnings.vue"),
      },
      {
        path: "/preguntas-frecuentes",
        name: "preguntas-frecuentes",
        component: () => import("@/views/Pages/FAQ.vue"),
      },
      {
        path: "/contacto",
        name: "contacto",
        component: () => import("@/views/Pages/Contact.vue"),
      },

      // Back Office

      // {
      // 	path: '/home',
      // 	name: 'home',
      // 	component: () => import('@/views/HomePage.vue'),
      // },
      {
        path: "/carga-de-archivos",
        name: "carga-de-archivos",
        component: () => import("@/views/BackOffice/UploadFiles.vue"),
      },
      {
        path: "/descargar-de-archivos",
        name: "descargar-de-archivos",
        component: () => import("@/views/BackOffice/DownloadFiles.vue"),
      },
      {
        path: "/gestion-de-usuarios",
        name: "gestion-de-usuarios",
        component: () => import("@/views/BackOffice/UserManagement.vue"),
      },
      {
        path: "/roles-y-permisos",
        name: "roles-y-permisos",
        component: () => import("@/views/BackOffice/RolesPermissions.vue"),
      },
      {
        path: "/permisos",
        name: "permisos",
        component: () => import("@/views/BackOffice/Permissions.vue"),
      },
      {
        path: "/categorias",
        name: "categorias",
        component: () => import("@/views/BackOffice/Categories.vue"),
      },
    ],
  },
  {
    path: "/auth",
    component: () => import("../views/Auth/AuthModule.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Auth/pages/SignIn.vue"
          ),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Auth/pages/SignUp.vue"
          ),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("../views/Auth/pages/PasswordReset.vue"),
      },
      {
        path: "/verification-code",
        name: "verification-code",
        component: () => import("../views/Auth/pages/VerificationCode.vue"),
      },
      {
        path: "/change-password",
        name: "change-password",
        component: () => import("../views/Auth/pages/ChangePassword.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  const user = computed(() => {
    return store.getters.currentUser;
  });

  const userRoutes = ["/mis-campanas", "/generar-campanas", "/mis-ganancias"];

  const adminRoutes = [
    "/carga-de-archivos",
    "/descargar-de-archivos",
    "/gestion-de-usuarios",
    "/roles-y-permisos",
    "/permisos",
    "/categorias",
  ];

  // if (userRoutes.includes(to.fullPath)) {
  // 	router.push({ name: 'mantenimiento' });
  // }

  // if (to.fullPath == '/perfil' && user.value.admin == 0) {
  // 	router.push({ name: 'mantenimiento' });
  // }

  if (adminRoutes.includes(to.fullPath) && user.value.admin == 0) {
    router.push({ name: "mis-campanas" });
  }

  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  console.log(to.fullPath);

  if (to.fullPath != ("/sign-in" || "/" || "sign-up")) {
    await store
      .dispatch(Actions.VERIFY_AUTH, {
        token: `${JwtService.getToken()}`,
      })
      .catch((res) => router.push({ name: "/" }));
  }

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
