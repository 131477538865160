import ApiService from '@/core/services/ApiService';
import { Action, Module, VuexModule } from 'vuex-module-decorators';
import { Actions } from '../enums/StoreEnums';

@Module
export default class DashboardModule extends VuexModule {
	@Action
	[Actions.DASHBOARD_OVERVIEW](values: any) {
		ApiService.setHeader();
		return ApiService.query('api/statistics', {
			params: values,
		})
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}

	@Action
	[Actions.DASHBOARD_OVERVIEW_RANGE](values: any) {
		ApiService.setHeader();
		return ApiService.query('api/statistics/tracking/range', {
			params: values,
		})
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}

	// @Action
	// [Actions.SUMMARY_BY_CAMPAIGN](values: any) {
	// 	ApiService.setHeader();
	// 	return ApiService.query('api/statistics', {
	// 		params: values,
	// 	})
	// 		.then(res => {
	// 			return res;
	// 		})
	// 		.catch(({ response }) => {
	// 			return response;
	// 		});
	// }

	@Action
	[Actions.SUMMARY_BY_CAMPAIGN](values: any) {
		ApiService.setHeader();
		return ApiService.query('api/statistics/tracking/campaign', {
			params: values,
		})
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}
}
