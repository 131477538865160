import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class UserModule extends VuexModule {

	@Action
	[Actions.GET_PROFILE]() {
		ApiService.setHeader();
		return ApiService.get('/api/user/profile')
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}

	@Action
	[Actions.UPDATE_PROFILE](userValues: any) {
		ApiService.setHeader();
		return ApiService.put('/api/user/profile', userValues)
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}

  @Action
  [Actions.GET_TOTAL_COMISSION](values: any) {
    return ApiService.query("api/purchase/tracking/commission", {
      params: values,
    })
      .then((res) => {
        return res;
      })
      .catch(({ response }) => {
        return response;
      });
  }
}
