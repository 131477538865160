import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "..";
import router from "@/router";
import { jwtDecode } from "jwt-decode";

export interface User {
  admin: number;
  category_id: string;
  deleted_at: string;
  document_number: string;
  document_type: string;
  email: string;
  lastname_father: string;
  lastname_mother: string;
  name: string;
  phone: string;
  status: number;
  username: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

interface ICategories {
  id: number;
  name: string;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  permissions = [];
  userId = "";
  isAuthenticated = false;
  listCategories = [] as Array<ICategories>;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    const userAlreadyExists = Object.keys(this.user).length > 0;
    if (userAlreadyExists) return this.user;

    const data = localStorage.getItem("data");
    let parseData;
    if (data == null || undefined) {
      parseData = this.permissions;
    } else {
      parseData = JSON.parse(data);
      const tokenInfo = jwtDecode(parseData.token) as any;
      parseData.user.admin = tokenInfo.user.admin;
    }

    return parseData.user;
  }

  get currentPermissions() {
    const data = localStorage.getItem("data");
    let parseData;
    if (data == null || undefined) {
      parseData = this.permissions;
    } else {
      parseData = JSON.parse(data);
    }
    return parseData.permissions;
  }

  get getUserId() {
    return this.userId;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  /**
   * Get authentification get all categories
   * @returns array
   */
  get getAllCategories(): Array<ICategories> {
    return this.listCategories;
  }

  @Mutation
  [Mutations.SET_ERROR](error: any) {
    this.errors = { ...error };
  }
  @Mutation
  [Mutations.PURGE_ERROR]() {
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_AUTH](data: any) {
    this.isAuthenticated = true;
    this.user = data.user as User;

    this.errors = {};

    if (data.token) {
      JwtService.saveToken(data.token);
      const tokenInfo = jwtDecode(data.token) as any;
      this.user.admin = tokenInfo.user.admin;
    }
  }

  @Mutation
  [Mutations.SET_PERMISSIONS](data: any) {
    this.permissions = data.permissions;
  }

  @Mutation
  [Mutations.SET_USER](user: any) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_USER_ID](data: any) {
    this.userId = data.user.id;
  }

  // @Mutation
  // [Mutations.SET_PASSWORD](password: any) {
  //   this.user.password = password;
  // }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Mutation
  [Mutations.SET_LIST_CATEGORIES](data: Array<ICategories>) {
    this.listCategories = data;
  }

  @Action
  [Actions.GET_CATEGORIES]() {
    return ApiService.all("/api/public/taxonomy/category/get")
      .then(({ data }) => {
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_LIST_CATEGORIES, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.message);
      });
  }

  @Action
  [Actions.LOGIN](credentials: any) {
    const token = localStorage.getItem("signIn_token");

    ApiService.vueInstance.axios.defaults.headers.common["Token"] = token;
    return ApiService.post("/api/auth", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PERMISSIONS, data);
        localStorage.setItem("data", JSON.stringify(data));
        this.context.commit(Mutations.SET_AUTH, data);
        this.context.commit(Mutations.SET_USER_ID, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.error);
        return response;
      });
  }

  @Action
  async [Actions.LOGOUT](payload: any) {
    ApiService.setHeader();
    await ApiService.post("api/auth/logout", payload);
    
    this.context.commit(Mutations.PURGE_AUTH);
    router.push({ name: "sign-in" });
  }

  @Action
  async [Actions.VERIFY_AUTH](payload: any) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      await ApiService.post("api/auth/validation", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data.data);
        })
        .catch((response) => {
          store.dispatch(Actions.LOGOUT);
          router.push({ name: "sign-in" });
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          // this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.REGISTER](credentials: any) {
    const getBrowserName = (navigator: any) => {
      if (navigator.userAgent.includes("Firefox")) {
        return "Mozilla Firefox";
      } else if (navigator.userAgent.includes("SamsungBrowser")) {
        return "Samsung Internet";
      } else if (
        navigator.userAgent.includes("Opera") ||
        navigator.userAgent.includes("OPR")
      ) {
        return "Opera";
      } else if (navigator.userAgent.includes("Trident")) {
        return "Microsoft Internet Explorer";
      } else if (navigator.userAgent.includes("Edge")) {
        return "Microsoft Edge (Legacy)";
      } else if (navigator.brave && !!navigator.brave) {
        return "Brave";
      } else if (navigator.userAgent.includes("Edg")) {
        return "Microsoft Edge (Chromium)";
      } else if (navigator.userAgent.includes("Chrome")) {
        return "Google Chrome or Chromium";
      } else if (navigator.userAgent.includes("Safari")) {
        return "Apple Safari";
      } else {
        return "unknown";
      }
    };

    const browserName = getBrowserName(navigator);

    ApiService.vueInstance.axios.defaults.headers.common["Browser"] =
      browserName;
    const token = localStorage.getItem("signUp_token");

    ApiService.vueInstance.axios.defaults.headers.common["Token"] = token;
    return ApiService.post("/api/auth/register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload: any) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.PASSWORD_RESET](payload: any) {
    return ApiService.post("/api/password/reset", payload)
      .then((res) => {
        return res;
      })
      .catch(({ response }) => {
        return response;
      });
  }

  @Action
  [Actions.PASSWORD_RESET_VALIDATE](payload: any) {
    const token = localStorage.getItem("token");

    ApiService.vueInstance.axios.defaults.headers.common[
      "Token-Reset"
    ] = `${token}`;
    return ApiService.post("/api/password/reset/validate", payload)
      .then((res) => {
        return res;
      })
      .catch(({ response }) => {
        return response;
      });
  }

  @Action
  [Actions.PASSWORD_RESET_VERIFY](payload: any) {
    const token = localStorage.getItem("token");
    const verifyCodeToken = localStorage.getItem("verify_code_token");
    ApiService.vueInstance.axios.defaults.headers.common[
      "Token-Reset"
    ] = `${token}`;
    ApiService.vueInstance.axios.defaults.headers.common["Token"] =
      verifyCodeToken;
    return ApiService.post("/api/password/reset/verify", payload)
      .then((res) => {
        return res;
      })
      .catch(({ response }) => {
        return response;
      });
  }

  @Action
  [Actions.PASSWORD_UPDATE](payload: any) {
    const token = localStorage.getItem("token");
    ApiService.vueInstance.axios.defaults.headers.common[
      "Token-Reset"
    ] = `${token}`;
    return ApiService.put("/api/password", payload)
      .then((res) => {
        return res;
      })
      .catch(({ response }) => {
        return response;
      });
  }
}
