import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';

import AuthModule from '@/store/modules/AuthModule';
import BodyModule from '@/store/modules/BodyModule';
import BreadcrumbsModule from '@/store/modules/BreadcrumbsModule';
import ConfigModule from '@/store/modules/ConfigModule';
import ThemeModeModule from '@/store/modules/ThemeModeModule';
import UserModule from './modules/UserModule';
import CampaignModule from './modules/CampaignModule';
import BulkUploadModule from './modules/BulkUploadModule';
import DashboardModule from './modules/DashboardModule';
import EarningModule from './modules/EarningModule';
import BackOfficeModule from './modules/BackOfficeModule';

config.rawError = true;

const store = createStore({
	modules: {
		AuthModule,
		BodyModule,
		BreadcrumbsModule,
		ConfigModule,
		ThemeModeModule,
		UserModule,
		CampaignModule,
		BulkUploadModule,
		DashboardModule,
		EarningModule,
		BackOfficeModule,
	},
});

export default store;
