import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '../enums/StoreEnums';
import ApiService from '@/core/services/ApiService';

interface IPermissions {
	id: number;
	name: string;
}

interface IRoles {
	id: number;
	name: string;
	createdAt: string;
	status: string;
}

interface IUserRoles {
	role_id: number;
}

interface IUserRoles {
	role_id: number;
}

@Module
export default class BulkUploadModule extends VuexModule {
	listPermissions = [] as Array<IPermissions>;
	listRoles = [] as Array<IRoles>;
	listUserRoles = [] as Array<IUserRoles>;
	listPermissionsById = [] as Array<IPermissions>;

	/**
	 * Get BackOffice get all roles
	 * @returns array
	 */
	get getAllPermissions(): Array<IPermissions> {
		return this.listPermissions;
	}

	/**
	 * Get BackOffice get all roles
	 * @returns array
	 */
	get getAllPermissionsById(): Array<IPermissions> {
		return this.listPermissionsById;
	}

	/**
	 * Get BackOffice get all roles
	 * @returns array
	 */
	get getAllRoles(): Array<IRoles> {
		return this.listRoles;
	}

	/**
	 * Get BackOffice get all roles
	 * @returns array
	 */
	get getAllUsersRoles(): Array<IUserRoles> {
		return this.listUserRoles;
	}

	// Mutations

	@Mutation
	[Mutations.SET_LIST_PERMISSIONS](data: Array<IPermissions>) {
		this.listPermissions = data;
	}

	@Mutation
	[Mutations.SET_LIST_PERMISSIONS_BY_ID](data: Array<IPermissions>) {
		this.listPermissionsById = data;
	}

	@Mutation
	[Mutations.SET_LIST_ROLES](data: Array<IRoles>) {
		this.listRoles = data;
	}

	@Mutation
	[Mutations.SET_LIST_USER_ROLES](data: Array<IUserRoles>) {
		this.listUserRoles = data;
	}
	// Actions

	@Action
	[Actions.UPLOAD_PAYMENT_CONFIRMATION](values: any) {
		ApiService.setHeader();
		return ApiService.put('/api/payment/tracking/massive', values)
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}

	@Action
	[Actions.UPLOAD_BANK_STATE_RECORD](values: any) {
		ApiService.setHeader();
		return ApiService.put('/api/user/bank-account/massive', values)
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}

	@Action
	[Actions.UPLOAD_DNIS](values: any) {
		return ApiService.post('/api/user/partner/massive', values)
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}

	@Action
	[Actions.DOWNLOAD_USERS]() {
		ApiService.setHeader();
		return ApiService.all('api/user/person/get')
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
				this.context.commit(Mutations.SET_ERROR, response.data.message);
			});
	}

	@Action
	[Actions.DOWNLOAD_COMISSIONS_PAYMENT](values: any) {
		ApiService.setHeader();
		return ApiService.query('/api/statistics/payment/sales', {
			params: values,
		})
			.then(res => {
				return res;
			})
			.catch(({ res }) => {
				return res;
			});
	}

	@Action
	[Actions.DOWNLOAD_PERFOMANCE](values: any) {
		ApiService.setHeader();
		return ApiService.query('/api/statistics/tracking/users', {
			params: values,
		})
			.then(res => {
				return res;
			})
			.catch(({ res }) => {
				return res;
			});
	}

	@Action
	[Actions.DOWNLOAD_RED_SOCIAL](values: any) {
		ApiService.setHeader();
		return ApiService.query('/api/statistics/tracking/users-campaigns', {
			params: values,
		})
			.then(res => {
				return res;
			})
			.catch(({ res }) => {
				return res;
			});
	}

	// User Management
	@Action
	[Actions.GET_ALL_USERS]() {
		ApiService.setHeader();
		return ApiService.query('/api/user/person/get', {
			params: { skip_ignore: 1 },
		})
			.then(res => {
				return res;
			})
			.catch(({ res }) => {
				return res;
			});
	}

	@Action
	[Actions.CREATE_USERS](values: any) {
		ApiService.setHeader();
		return ApiService.post('/api/user/person', values)
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}

	@Action
	[Actions.UPDATE_USERS](values: any) {
		ApiService.setHeader();
		return ApiService.put('/api/user/person', values)
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}

	@Action
	[Actions.DELETE_USERS](values: any) {
		ApiService.setHeader();
		return ApiService.delete('/api/user/person', { params: values })
			.then(res => {
				return res;
			})
			.catch(({ res }) => {
				return res;
			});
	}

	@Action
	[Actions.CREATE_USER_ROL](values: any) {
		ApiService.setHeader();
		return ApiService.post('/api/capability/user-role', values)
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}

	@Action
	[Actions.UPDATE_USER_ROL](values: any) {
		ApiService.setHeader();
		return ApiService.put('/api/capability/user-role', values)
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}

	@Action
	[Actions.GET_ALL_USER_ROLES](values: any) {
		ApiService.setHeader();
		return ApiService.query('/api/capability/user-role/get', {
			params: values,
		})
			.then(({ data }) => {
				this.context.commit(Mutations.PURGE_ERROR);
				this.context.commit(Mutations.SET_LIST_USER_ROLES, data.data);
			})
			.catch(({ response }) => {
				this.context.commit(Mutations.SET_ERROR, response.data.message);
			});
	}

	// Rols & Permissions
	@Action
	[Actions.GET_ALL_PERMISSIONS]() {
		ApiService.setHeader();
		return ApiService.all('/api/capability/permission/get')
			.then(({ data }) => {
				this.context.commit(Mutations.PURGE_ERROR);
				this.context.commit(Mutations.SET_LIST_PERMISSIONS, data.data);
			})
			.catch(({ response }) => {
				this.context.commit(Mutations.SET_ERROR, response.data.message);
			});
	}

	// Permissions
	@Action
	[Actions.GET_ALL_ROLES]() {
		ApiService.setHeader();
		return ApiService.all('/api/capability/role/get')
			.then(({ data }) => {
				this.context.commit(Mutations.PURGE_ERROR);
				this.context.commit(Mutations.SET_LIST_ROLES, data.data);
			})
			.catch(({ response }) => {
				this.context.commit(Mutations.SET_ERROR, response.data.message);
			});
	}

	@Action
	[Actions.GET_ALL_PERMISSIONS_BY_ID](values: any) {
		ApiService.setHeader();
		return ApiService.query('/api/capability/role-permission/get', {
			params: values,
		})
			.then(({ data }) => {
				this.context.commit(Mutations.PURGE_ERROR);
				this.context.commit(Mutations.SET_LIST_PERMISSIONS_BY_ID, data.data);
			})
			.catch(({ response }) => {
				this.context.commit(Mutations.SET_ERROR, response.data.message);
			});
	}

	@Action
	[Actions.CREATE_ROLES](values: any) {
		ApiService.setHeader();
		return ApiService.post('/api/capability/role', values)
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}

	@Action
	[Actions.EDIT_ROLES](values: any) {
		ApiService.setHeader();
		return ApiService.put('/api/capability/role', values)
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}

	@Action
	[Actions.DELETE_ROLES](values: any) {
		ApiService.setHeader();
		return ApiService.delete('/api/capability/role', { params: values })
			.then(res => {
				return res;
			})
			.catch(({ res }) => {
				return res;
			});
	}

	// Categories
	@Action
	[Actions.GET_ALL_CATEGORIES]() {
		ApiService.setHeader();
		return ApiService.all('/api/taxonomy/category/get')
			.then(res => {
				return res;
			})
			.catch(({ res }) => {
				return res;
			});
	}

	@Action
	[Actions.CREATE_CATEGORIES](values: any) {
		ApiService.setHeader();
		return ApiService.post('/api/taxonomy/category', values)
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}

	@Action
	[Actions.UPDATE_CATEGORIES](values: any) {
		ApiService.setHeader();
		return ApiService.put('/api/taxonomy/category', values)
			.then(res => {
				return res;
			})
			.catch(({ res }) => {
				return res;
			});
	}

	@Action
	[Actions.DELETE_CATEGORIES](values: any) {
		ApiService.setHeader();
		return ApiService.delete('/api/taxonomy/category', { params: values })
			.then(res => {
				return res;
			})
			.catch(({ res }) => {
				return res;
			});
	}
}
