import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import { Action, Module, VuexModule } from 'vuex-module-decorators';
import { Actions } from '../enums/StoreEnums';

@Module
export default class BulkUploadModule extends VuexModule {
	@Action
	[Actions.MATCH_BULK_UPLOAD](values: any) {
		console.log('hola', JwtService.getToken());
		ApiService.setHeader();
		return ApiService.put('/api/purchase/tracking/massive', values)
			.then(res => {
				return res;
			})
			.catch(({ response }) => {
				return response;
			});
	}
}
