enum Actions {
	// action types
	ADD_BODY_CLASSNAME = 'addBodyClassName',
	REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
	ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
	REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
	ADD_CLASSNAME = 'addClassName',
	VERIFY_AUTH = 'verifyAuth',
	LOGIN = 'login',
	LOGOUT = 'logout',
	REGISTER = 'register',
	UPDATE_USER = 'updateUser',
	FORGOT_PASSWORD = 'forgotPassword',
	PASSWORD_RESET = 'passwordReset',
	PASSWORD_RESET_VALIDATE = 'passwordResetValidate',
	PASSWORD_RESET_VERIFY = 'passwordResetVerify',
	PASSWORD_UPDATE = 'passwordUpdate',
	SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
	SET_THEME_MODE_ACTION = 'setThemeModeAction',

	//Categories
	GET_CATEGORIES = 'getCategories',

	//User
	GET_PROFILE = 'getProfile',
	UPDATE_PROFILE = 'updateProfile',

	//Campaign
	CREATE_CAMPAIGN = 'createCampaign',

	//Total Commission
	GET_TOTAL_COMISSION = 'totalCommission',

	// Dashboard overview
	DASHBOARD_OVERVIEW = 'dashboardOverview',

	// Dashboard overview
	DASHBOARD_OVERVIEW_RANGE = 'dashboardOverviewRange',

	// Summary by campaign
	SUMMARY_BY_CAMPAIGN = 'summaryByCampaign',

	// Total Campaing
	GET_TOTAL_CAMPAINGS = 'totalCampaign',

	// Archive Campaign
	ARCHIVE_CAMPAIGN = 'archiveCampaign',

	// Total Commissions Earned
	GET_TOTAL_COMISSION_EARNED = 'commissionsEarned',

	// Total Commissions Payable
	GET_TOTAL_COMISSION_PAYABLE = 'commissionsPayable',

	// Total Commissions Paid
	GET_TOTAL_COMISSION_PAID = 'commissionsPaid',

	// Total Commissions Generated
	GET_TOTAL_COMISSION_GENERATED = 'commissionsGenerated',

	// REPORTS

	//Bulk Upload
	MATCH_BULK_UPLOAD = 'bulkUpload',

	// Upload Payment Confirmation
	UPLOAD_PAYMENT_CONFIRMATION = 'uploadPaymentConfirmation',

	// 	Upload Bank State Record
	UPLOAD_BANK_STATE_RECORD = 'uploadBankStateRecord',

	// Upload Dnis
	UPLOAD_DNIS = 'uploadDnis',

	// Users
	DOWNLOAD_USERS = 'downloadUsers',

	// Comissions payment
	DOWNLOAD_COMISSIONS_PAYMENT = 'downloadComissionsPayment',

	// Comissions payment
	DOWNLOAD_PERFOMANCE = 'downloadPerfomance',

	// Comissions payment
	DOWNLOAD_RED_SOCIAL = 'downloadRedSocial',

	// GET ALL USERS
	GET_ALL_USERS = 'getUsers',

	//  CREATE USERS
	CREATE_USERS = 'createUsers',

	// UPDATE USER ROL
	UPDATE_USERS = 'updateUsers',

	// DELETE CATEGORIES
	DELETE_USERS = 'deleteUsers',

	// CREATE USER ROL
	CREATE_USER_ROL = 'createUserRol',

	// UPDATE USER ROL
	UPDATE_USER_ROL = 'updateUserRol',

	// GET ALL ROLES
	GET_ALL_USER_ROLES = 'getAllUserRoles',

	// GET ALL ROLES BY ID
	GET_ALL_PERMISSIONS_BY_ID = 'getAllPermissionssById',

	// GET ALL PERMISSIONS
	GET_ALL_PERMISSIONS = 'getPermissions',

	// GET ALL ROLES
	GET_ALL_ROLES = 'getRoles',

	// CRAETE ALL ROLES
	CREATE_ROLES = 'createRoles',

	// EDIT ALL ROLES
	EDIT_ROLES = 'editRoles',

	// DELETE CATEGORIES
	DELETE_ROLES = 'deleteRoles',

	// GET ALL CATEGORES
	GET_ALL_CATEGORIES = 'getAllCategories',

	// CREATE CATEGORIES
	CREATE_CATEGORIES = 'createCategories',

	// UPDATE CATEGORIES
	UPDATE_CATEGORIES = 'updateCategories',

	// DELETE CATEGORIES
	DELETE_CATEGORIES = 'deleteCategories',
}

enum Mutations {
	// mutation types
	SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
	PURGE_AUTH = 'logOut',
	SET_AUTH = 'setAuth',
	SET_USER = 'setUser',
	SET_PERMISSIONS = 'setPermissions',
	SET_USER_ID = 'setUserId',
	SET_PASSWORD = 'setPassword',
	SET_ERROR = 'setError',
	PURGE_ERROR = 'purgeError',
	SET_LIST_PERMISSIONS = 'setListPermissions',
	SET_LIST_ROLES = 'setListRoles',
	SET_LIST_CATEGORIES = 'setListCategories',
	SET_LIST_USER_ROLES = 'setListUserRoles',
	SET_LIST_PERMISSIONS_BY_ID = 'setListPermissionsById',

	SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
	SET_LAYOUT_CONFIG = 'setLayoutConfig',

	RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
	OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
	OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',
	SET_THEME_MODE_MUTATION = 'setThemeModeMutation',
}

export { Actions, Mutations };
